<!-- 考场统计表 -->
<template>
  <div class="examinationRoom">
    <el-form
      id="form"
      ref="form"
      class="form"
      inline
      label-width="120px"
      :model="form"
    >
      <el-form-item label="考试日期">
        <el-date-picker
          v-model="form.examTime"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <!-- <el-form-item label="考试场次">
        <el-select
          v-model="form.examNum"
          filterable
          placeholder="请输入并选择考试场次"
          :clearable="true"
        >
          <el-option
            v-for="item in form.examNumOpt"
            :key="item.id"
            :label="item.exam_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="所属机构">
        <el-select
          v-model="form.belonging"
          filterable
          placeholder="请选择所属机构"
          :clearable="true"
          @change="belongingChange"
        >
          <el-option
            v-for="item in form.belongingChoose"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考场">
        <div style="width: 276px">
          <el-radio-group v-model="form.room">
            <el-radio :label="0">全部</el-radio>
            <el-radio :label="1">单科考场</el-radio>
            <el-radio :label="2">综合考场</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="考试大类">
        <el-select
          v-model="form.exam"
          filterable
          placeholder="请选择考试大类"
          :clearable="true"
        >
          <el-option
            v-for="item in form.examOpt"
            :key="item.id"
            :label="item.exam_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单据状态">
        <el-select
          v-model="form.condition"
          placeholder="请选择单据状态"
          :clearable="true"
        >
          <el-option
            v-for="item in form.conditionChoose"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关键字">
        <el-input v-model="form.search" placeholder="请输入关键字"></el-input>
      </el-form-item>
      <div class="" style="margin-left: 22px; margin-top: 5px">
        <el-button
          size="small"
          style="background: #18bc9c; color: #ffffff"
          @click="query"
        >
          查询
        </el-button>
        <el-button
          size="small"
          style="background: #fafafa; color: #666666"
          @click="Reset"
        >
          重置
        </el-button>
      </div>
    </el-form>
    <div class="" style="margin-left: 22px">
      <button class="print" @click="print">打印预览</button>
      <button
        class="button-1"
        type="button"
        @click="toTab(0)"
        :class="tab == 0 ? 'active' : ''"
      >
        列表
      </button>
      <button
        class="button-2"
        type="button"
        @click="toTab(1)"
        :class="tab == 1 ? 'active' : ''"
      >
        统计表
      </button>
      <button class="derive" @click="derive">导出到Excel</button>
    </div>
    <!-- 列表 表格 -->
    <el-table
      :data="listData"
      border
      stripe
      @selection-change="checkAll"
      style="width: 97.9%; flex: 1"
      height="650"
      v-if="tab === 0"
      :key="0"
      @sort-change="abc"
    >
      <!-- 复选按钮 -->
      <el-table-column type="selection" align="center" width="50">
      </el-table-column>
      <el-table-column
        prop="status"
        sortable="custom"
        label="审核状态"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1">未审核</span>
          <span v-else-if="scope.row.status == 2">已审核</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="exam_type"
        sortable="custom"
        label="考场类A"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="arrange_date"
        sortable="custom"
        label="制单日期"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="area_name"
        sortable="custom"
        label="考场名称"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="area_type"
        sortable="custom"
        label="考场类B"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="exam_teacher"
        sortable="custom"
        label="监考官"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="org_name"
        sortable="custom"
        label="机构/科目"
        align="center"
        width="150"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="level"
        sortable="custom"
        label="等级"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="exam_date"
        sortable="custom"
        label="考试日期"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="exam_starttime"
        sortable="custom"
        label="开始时间"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="exam_endtime"
        sortable="custom"
        label="结束时间"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="num"
        sortable="custom"
        label="计划人数"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="num2"
        sortable="custom"
        label="实际人数"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="admission_num"
        sortable="custom"
        label="准考人数"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="complete_num"
        sortable="custom"
        label="考生OK人数"
        align="center"
        width="130"
      >
      </el-table-column>
      <el-table-column
        prop="cancel_num"
        sortable="custom"
        label="取消人数"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="refund_num"
        sortable="custom"
        label="退款人数"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="delay_num"
        sortable="custom"
        label="延期人数"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="examarea_rate"
        sortable="custom"
        label="考场使用率(%)"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="agroup"
        sortable="custom"
        label="A分组码"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="bgroup"
        sortable="custom"
        label="B分组码"
        align="center"
        width="120"
      >
      </el-table-column>
      <!-- <el-table-column prop="cancel_numa" sortable="custom" label="取消人数A" align="center" width="120">
            </el-table-column>
            <el-table-column prop="total" sortable="custom" label="记录数" align="center" width="120">
            </el-table-column> -->

      <!-- <el-table-column prop="cancel_numb" sortable="custom" label="取消人数B" align="center" width="120">
            </el-table-column>
            <el-table-column prop="defect_num" sortable="custom" label="缺考人数" align="center" width="120">
            </el-table-column> -->
      <el-table-column
        prop="area_address"
        sortable="custom"
        label="地址"
        align="center"
        width="300"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column label="操作" align="center" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button
            class="checkBtn"
            icon="el-icon-view"
            size="mini"
            @click="check(scope.$index, scope.row)"
          >
            查看更多
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--统计表 表格   -->
    <el-table
      :data="censusData"
      border
      stripe
      @selection-change="checkAll"
      style="width: 97.9%; flex: 1"
      height="650"
      v-if="tab === 1"
      :key="1"
      @sort-change="abc"
    >
      <!-- 复选按钮 -->
      <el-table-column type="selection" align="center" width="50">
      </el-table-column>
      <el-table-column
        prop="agroup"
        sortable="custom"
        label="分组码"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="number"
        sortable="custom"
        label="安排单号"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="arrange_date"
        sortable="custom"
        label="制单日期"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="area_name"
        sortable="custom"
        label="考场名称"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="exam_teacher"
        sortable="custom"
        label="监考老师"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="org_name"
        sortable="custom"
        label="机构/科目"
        align="center"
        width="150"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="exam_date"
        sortable="custom"
        label="考试日期"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="exam_starttime"
        sortable="custom"
        label="开始时间"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="exam_endtime"
        sortable="custom"
        label="结束时间"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="area_address"
        sortable="custom"
        label="地址"
        align="center"
        width="200"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="num"
        sortable="custom"
        label="计划人数"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="num2"
        sortable="custom"
        label="实际人数"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="cancel_numa"
        sortable="custom"
        label="取消人数A"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="total"
        sortable="custom"
        label="记录数"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="examarea_rate"
        sortable="custom"
        label="考场使用率(%)"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="cancel_numb"
        sortable="custom"
        label="取消人数B"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="defect_num"
        sortable="custom"
        label="缺考人数B"
        align="center"
        width="120"
      >
      </el-table-column>
    </el-table>
    <div class="" style="display: flex; justify-content: space-between">
      <div class="">
        <el-form :model="rulform" inline class="form_2" label-width="100px">
          <el-form-item label="计划考生人数">
            <el-input :value="rulform.project" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="实际考生人数">
            <el-input v-model="rulform.actual" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="取消考生人数">
            <el-input v-model="rulform.cancel" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="缺考考生人数">
            <el-input v-model="rulform.missExam" :disabled="true"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!--分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[20, 50, 100, 200, 500, 1000]"
        :page-size="10"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 打印预览弹窗 -->
    <el-dialog
      width="900px"
      :visible.sync="dialog"
      id="dialog"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div id="printRollcall">
        <!-- 点名表内容 -->
        <div class="rollcall" v-for="(item, index) in rollcall" :key="index">
          <!-- 头部 -->
          <h2>{{ item.year }}年 中国美术学院社会美术水平考试 点名表</h2>
          <div class="center">
            <div class="c_1">考场名称 : {{ item.site_name }}</div>
            <div class="c_2">
              共 {{ item.sum_page }} 页-第 {{ index + 1 }} 页
            </div>
          </div>
          <!-- 中间 -->
          <div class="center">
            <div class="c_1">
              机构：
              <!-- {{item.org_name}} -->
            </div>
            <!-- ( {{ item.exam_startdate }} --{{ item.exam_enddate }} ) -->
            <div class="c_2">考试时间 : {{ item.exam2_date }}</div>
          </div>
          <div class="center">
            <div class="c_1">
              <!-- 监考老师 : <input :value="item.teacher" style="font-size: 16px" /> -->
              监考老师 : {{ item.teacher }}
            </div>
            <div
              class="c_2"
              style="padding-right: 90px; font-size: 14px"
              v-if="item.exam_startdate != ''"
            >
              {{ item.exam_startdate }} ~ {{ item.exam_enddate }}
            </div>
          </div>
          <!-- 表格 -->
          <el-table
            :data="item.list"
            border
            :header-cell-style="{ background: '#ffff7f', color: '#333' }"
          >
            <el-table-column
              prop="number"
              label="序号"
              align="center"
              width="40"
            >
            </el-table-column>
            <el-table-column
              prop="certificate_no"
              label="准考证号"
              align="center"
              width="160"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="name" label="姓名" align="center" width="70">
            </el-table-column>
            <el-table-column
              prop="english_name"
              label="拼音名"
              align="center"
              width="150"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="gender"
              label="性别"
              align="center"
              width="30"
            >
            </el-table-column>
            <el-table-column
              prop="nation"
              label="民族"
              align="center"
              width="70"
            >
            </el-table-column>
            <el-table-column
              prop="birthdate"
              label="出生日期"
              align="center"
              width="90"
            >
            </el-table-column>
            <el-table-column
              prop="course"
              label="报考专业"
              align="center"
              width="70"
            >
            </el-table-column>
            <el-table-column
              prop="exam_level"
              label="报考级别"
              align="center"
              width="60"
            >
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              align="center"
              width="100"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="record">
          <div class="">
            <span>考生数量：</span><input type="" :value="sum" disabled />
          </div>
          <div class="">
            <span>实考人数：</span><input type="" value="" disabled />
          </div>
          <div class="">
            <span>缺考人数：</span><input type="" value="" disabled />
          </div>
          <div class="">
            <span>监考签名：</span><input type="" value="" disabled />
          </div>
        </div>
        <p style="color: #333; padding: 15px 0px; font-size: 15px">
          <span style="font-weight: bold"> 备注说明 : </span>
          <input
            type="text"
            value=""
            style="
              width: 91%;
              border: none;
              border-bottom: 1px solid #333;
              outline: none;
              background: #fff;
            "
            disabled
          />
        </p>
      </div>
      <!-- 底部打印按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button
          style="background: #18bc9c; color: #fff"
          v-print="'#printRollcall'"
        >
          打 印
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        search: "", //关键字
        examTime: "", //考试日期
        examNum: "", //考试场次
        examNumOpt: [], //场次选项
        belonging: "", //所属机构
        belongingChoose: [], //所属机构选择
        room: 0, //考场
        exam: "", //考试大类
        examOpt: [], //考试大类选项
        condition: 0, //单据状态
        conditionChoose: [
          {
            value: 0,
            label: "全部",
          },
          {
            value: 1,
            label: "草稿",
          },
          {
            value: 2,
            label: "报考",
          },
          {
            value: 3,
            label: "准考",
          },
          {
            value: 4,
            label: "考试OK",
          },
          {
            value: 5,
            label: "取消",
          },
          {
            value: 6,
            label: "退款取消",
          },
          {
            value: 7,
            label: "延期",
          },
          {
            value: 8,
            label: "缺考",
          },
        ], //单据状态选择
      },
      // 机构名称，打印是使用
      org_name: "",
      listData: [], //列表数据
      censusData: [], //统计表数据
      tab: 0, //代表下标
      currentPage: 1,
      currentLimit: 20, //条数
      total: 0,
      rulform: {
        project: "", //计划考生人数
        actual: "", //实际考生人数
        cancel: "", //取消考生人数
        missExam: "", //缺考考生人数
      },
      dialog: false, //判断打印预览是否开启
      rollcall: [
        {
          year: "", //年
          room_name: "", //考场名称
          sum_page: "5", //总页数
          // page:"1",//第几页
          subject_grade: "", //科目与等级
          test_time: "", //考试时间
          teacher: "", //监考老师
          list: [
            {
              date: " MH3.21070011-XTDZ",
              name: "王小虎",
              name2: "wang xiao hu",
              address: "上海市-普陀区-金沙江",
              sex: "男",
              nation: "汉族", //民族
              birthday: "2001-12-12",
              major: "漫画漫画", //专业
              level: "三级", //级别
            },
          ],
        },
      ], //点名表数据
      sum: "", //考生数量
      actual_num: "", //实考人数
      lack_num: "", //缺考人数
      explain: "", //备注说明
      id: "", //接收打印所需id
      arr: [],
    };
  },
  created() {
    console.log(this.form, "form");
    // 考试场次
    this.$request({
      url: "/api/ExamCategory/list",
      method: "POST",
      data: {
        page: 1,
        limit: 100,
      },
    }).then((res) => {
      // console.log(res,'考试场次')
      if (res.code == 1) {
        this.form.examNumOpt = res.data.list;
      }
    });

    // 所属机构
    this.$request({
      url: "/api/mechanism/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log(res,'所属机构')
      if (res.code == 1) {
        this.form.belongingChoose = res.data.list;
      }
    });
    //考试大类
    this.$request({
      url: "/api/ExamCategory/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log("考试大类", res.data.list);
      this.form.examOpt = res.data.list;
    });

    this.getList();
  },
  activated() {
    this.getList();
  },
  methods: {
    belongingChange(id) {
      if (id) {
        // console.log(id,this.form.belongingChoose);
        const org = this.form.belongingChoose.find((e) => e.id == id);
        this.org_name = org ? org.name : "";
      } else {
        this.org_name = "";
      }
    },
    getList() {
      if (this.tab == 0) {
        this.$request({
          url: "/api/examarrange/list",
          method: "POST",
          data: {
            page: this.currentPage,
            limit: this.currentLimit,
            exam_date: this.form.examTime, //考试日期
            exam_type: this.form.room, //考场
            // apply_category: this.form.examNum, //考试场次
            org_id: this.form.belonging, //机构id
            sort: this.arr,
            apply_category: this.form.exam, //考试大类id
            status: this.form.condition,
            search: this.form.search,
          },
        }).then((res) => {
          console.log(res);
          if (res.code == 1) {
            this.listData = res.data.list;
            this.total = res.data.total;

            this.rulform.project = res.data.count1; //计划考生人数
            this.rulform.actual = res.data.count2; //实际考生人数
            this.rulform.cancel = res.data.count3; //取消考生人数
            this.rulform.missExam = res.data.count4; //缺考考生人数
          }
        });
      } else if (this.tab == 1) {
        this.$request({
          url: "/api/examarrange/list",
          method: "POST",
          data: {
            page: this.currentPage,
            limit: this.currentLimit,
            exam_date: this.form.examTime, //考试日期
            exam_type: this.form.room, //考场
            apply_category: this.form.exam, //考试场次
            org_id: this.form.belonging, //机构id
            sort: this.arr,
            status: this.form.condition,
            search: this.form.search,
          },
        }).then((res) => {
          // console.log(res)
          if (res.code == 1) {
            this.censusData = res.data.list;
            this.total = res.data.total;

            this.rulform.project = res.data.count1; //计划考生人数
            this.rulform.actual = res.data.count2; //实际考生人数
            this.rulform.cancel = res.data.count3; //取消考生人数
            this.rulform.missExam = res.data.count4; //缺考考生人数
          }
        });
      }
    },
    toTab(index) {
      //赋值下标
      this.tab = index;
      this.arr = [];
      this.getList();
    },
    check(index, row) {
      //查看更多
      this.$router.push({
        path: "statisticsDetails",
        query: {
          id: row.id,
        },
      });
    },
    handleSizeChange(val) {
      this.currentLimit = val;
      this.getList();
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
      // console.log(`当前页: ${val}`);
    },
    query() {
      //查询
      this.currentPage = 1;
      this.getList();
    },
    Reset() {
      //重置
      this.form.examTime = ""; //考试日期
      this.form.examNum = ""; //考试场次
      this.form.belonging = ""; //所属机构
      this.form.room = 0; //考场
      this.arr = [];
      this.exam = "";
      this.form.condition = 0;
      this.form.search = ""; //关键字
      this.getList();
    },
    checkAll(selection) {
      //获取批量id
      // console.log(selection)
      this.id = "";
      selection.forEach((item) => {
        this.id = item.id;
      });
      // console.log(this.id)
    },
    print() {
      //打印预览
      this.dialog = true;
      this.$request({
        url: "/api/statistics/callroll",
        method: "POST",
        data: {
          examarea_id: this.id,
        },
      }).then((res) => {
        console.log(res, "预览数据");
        if (res.code == 1) {
          this.rollcall = res.data;
          this.sum = res.data[0].count;
        } else {
          this.dialog = false;
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    derive() {
      //导出
      //   console.log(this.form.condition, "this.from.condition");
      //   return;
      // apply_category: this.form.examNum, //考试大类id
      location.href = `https://artxt.szart.cn/api/public/index.php/api/examarrange/list?page=${this.currentPage}&limit=${this.currentLimit}&exam_date=${this.form.examTime}&exam_type=${this.form.room}&org_id=${this.form.belonging}&apply_category=${this.form.exam}&status=${this.form.condition}&search=${this.form.search}`;
    },
    // 姓名排序
    fn2(a, b) {
      return a.exam_teacher.localeCompare(b.exam_teacher);
    },
    //排序
    abc(i) {
      console.log(i);
      this.listData = []; //列表数据
      this.censusData = []; //统计表数据
      this.arr = [];
      this.arr.push({
        title: i.prop,
        order: i.order,
      });
      this.getList();
    },
  },
};
</script>

<style scoped="scoped">
.examinationRoom {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.form {
  padding-top: 34px;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}
#form ::v-deep .el-radio__input.is-checked .el-radio__inner {
  background-color: #18bc9c;
}

#form ::v-deep.el-radio__input.is-checked + .el-radio__label {
  color: #18bc9c;
}

#form ::v-deep .el-radio__inner:hover {
  border-color: #18bc9c;
}
#form ::v-deep .el-input__inner {
  width: 276px;
  height: 37px;
}

#form ::v-deep .el-form-item__label {
  color: #000000;
}
/* 打印预览 */
.print {
  display: inline-block;
  padding-left: 10px;
  width: 94px;
  height: 32px;
  border-radius: 3px;
  background: url(../../assets/print.png) 8px 9px no-repeat #18bc9c;
  border: none;
  color: #ffffff;
  margin-right: 32px;
  cursor: pointer;
}
/* 导出按钮 */
.derive {
  display: inline-block;
  width: 113px;
  height: 32px;
  padding-left: 10px;
  vertical-align: middle;
  background: url(../../assets/export.png) 8px 8px no-repeat #ff7300;
  color: #ffffff;
  border-radius: 3px;
  border: none;
  margin-left: 10px;
  cursor: pointer;
  outline: none;
}
/* 列表 统计表按钮 */
.button-1 {
  font-size: 16px;
  display: inline-block;
  width: 70px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #29726c;
  border-radius: 3px;
  vertical-align: bottom;
  cursor: pointer;
}

.button-2 {
  font-size: 16px;
  display: inline-block;
  width: 90px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #29726c;
  margin-left: 10px;
  border-radius: 3px;
  vertical-align: bottom;
  cursor: pointer;
}

/* 选中后的样式 */
.active {
  background-color: #cdeeeb;
}

/* 表格 */
.el-table {
  margin: 20px;
}

::v-deep .el-table__cell {
  color: #000;
  padding: 0;
  height: 29px;
}
/* 查看更多 按钮 */
.checkBtn {
  color: #ffffff;
  background: #18bc9c;
  font-size: 12px;
  border-radius: 2px;
  border: none;
}
/* 底部显示 */
.form_2 {
  margin-left: 20px;
  /* margin-top: 20px; */
}
.form_2::v-deep .el-input__inner {
  width: 100px;
  height: 32px;
  border: 1px solid #ccc;
  background-color: #ffff;
  color: #000000;
}
.form_2::v-deep .el-form-item {
  margin-bottom: 5px !important;
}
.form_2::v-deep .el-form-item__label {
  color: #000000;
}
/* 分页 */
.el-pagination {
  margin: 20px;
  margin-top: 0px;
}

::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2c3e50 !important;
}

/* 打印预览样式 */
.examinationRoom ::v-deep .el-dialog__body {
  padding: 0px 15px;
}

.examinationRoom ::v-deep .el-dialog__footer {
  padding: 10px 20px 20px !important;
  text-align: center;
}

#printRollcall {
  width: 850px;
  margin: auto;
}

.rollcall {
  width: 100%;
  color: #000;
}

/* 标题 */
.rollcall h2 {
  text-align: center;
  color: #333;
}

/* 头部内容 */
.rollcall .top {
  padding: 0px 10px;
  margin-top: 10px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.rollcall .top .top_2 input {
  width: 100px;
  outline: none;
  border: none;
}

.rollcall .center {
  padding: 6px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.rollcall .center .c_2 {
  color: blue;
  padding-right: 20px;
}
/* 表格 */
.rollcall .el-table {
  width: 100% !important;
  margin: 0px auto;
  margin-top: 10px;
  padding: 0px;
}

.rollcall ::v-deep .el-table__cell {
  height: 35px;
  padding: 0px !important;
}

.rollcall ::v-deep .el-table th.el-table__cell > .cell {
  padding: 0px !important;
}

.rollcall ::v-deep .el-table .cell {
  padding: 0px !important;
}

.rollcall ::v-deep .el-table--border,
.el-table--group {
  border: 1px solid #000000;
  border-color: #000;
}

.rollcall:nth-child(1) {
  margin-bottom: 25px;
}

/* 数据 */
.record {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

.record span {
  font-weight: bold;
  color: #333;
}

.record input {
  outline: none;
  border: none;
  border-bottom: 1px solid #333;
  background: #fff;
  width: 100px;
  color: #333;
  padding-left: 5px;
}
</style>
